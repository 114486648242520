
import { defineComponent, ref, watch } from "vue";
import { compact } from "lodash";
import { oneOf, safeObj } from "@/uikit/_utils/assist";

// import Spinner from "../spinner";

export default defineComponent({
  name: "Button",
  // components: { Spinner },
  props: [
    "type",
    "shape",
    "size",
    "loading",
    "disabled",
    "icon",
    "circle",
    "plain",
    "round",
    "nativeType"
  ],
  setup(props) {
    const validator =
      oneOf("<Button> type", props.type, [
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "text"
      ]) && oneOf("<Button> size", props.size, ["medium", "small", "mini"]);
    if (!validator) return;
    const safeProps = safeObj(props);
    const {
      type,
      size,
      loading,
      disabled,
      circle,
      round,
      plain
    } = safeProps.getVals(
      ["type", "size", "loading", "disabled", "circle", "round", "plain"],
      ["", "", false, false, false, false, false]
    );

    const className = ref<string[]>([]);
    const x = [
      type && `el-button--${type}`,
      size && `el-button--${size}`,
      "is-loading" && loading,
      "is-disabled" && disabled,
      "is-circle" && circle,
      "is-round" && round,
      "is-plain" && plain
    ];
    className.value = compact([
      type && `el-button--${type}`,
      size && `el-button--${size}`,
      loading ? "is-loading" : undefined,
      disabled ? "is-disabled" : undefined,
      circle ? "is-circle" : undefined,
      round ? "is-round" : undefined,
      plain ? "is-plain" : undefined
    ]);

    watch(
      () => props.loading,
      (newVal, oldVal) => {
        if (newVal) className.value.push("is-loading");
        if (!newVal)
          className.value = className.value.filter(x => x !== "is-loading");
      }
    );
    return {
      className
    };
  }
});
