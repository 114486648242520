import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["disabled", "type"]
const _hoisted_2 = {
  key: 0,
  class: "fal fa-spinner fa-spin mr-1"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["el-button", [_ctx.className, { disabled: _ctx.disabled }]]),
    style: _normalizeStyle({ width: _ctx.circle, height: _ctx.circle, padding: _ctx.circle ? 0 : '' }),
    disabled: _ctx.disabled || _ctx.loading,
    type: _ctx.nativeType
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("i", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ], 14, _hoisted_1))
}